import { createReducer, on } from '@ngrx/store';
import * as CustomMenuActions from './custom-menu.actions';
import { MenuStructure } from './../../layout/main-menu/menu-structure';

export interface CustomMenu {
  loading: boolean;
  loaded: boolean;
  entries: MenuStructure[];
}

export const customMenuFeatureKey = 'custom_menu';
export const initialState: CustomMenu = {
  loading: false,
  loaded: false,
  entries: []
};

export const customMenuReducer = createReducer(
  initialState,

  on(CustomMenuActions.getCustomMenu, state => ({
    ...state,
    loading: true
  })),

  on(CustomMenuActions.getCustomMenuSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    entries: action.data?.map ? action.data.map((elem: any) => ({ name: elem.name, order: elem.order, id: elem.id, children: elem.childs })) : []
  })),

  on(CustomMenuActions.getCustomMenuFailure, state => ({
    ...state,
    loading: false,
    loaded: false
  }))
);
